<template>
    <div class="engine-detail-page" v-if="info">
        <el-card class="engine-detail-card" style="margin-top:0;">
            <div class="title-header-content">
                <div class="left">
                    <svg-icon :name="`ie-${info.icon}`" style="width: 28px;height: 28px;"></svg-icon>
                    <span class="engine-name margin-l-10">{{info.engineName}}</span>
                    <div class="quick-engine-lable margin-l-10" v-if="info.modelType === 'gpt'">快速训练</div>
                    <div class="deep-engine-lable margin-l-10" v-if="info.modelType === 'cmt'">深度训练</div>
                </div>
                <div class="right">
                    <el-dropdown @command="commandHandle" v-if="info.canEdit">
                        <span class="el-dropdown-link" style="margin-left:10px;cursor:pointer;">
                            <svg-icon name="ib-more" className="fonts-16"></svg-icon>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="delete" style="color: #F90606;">删除引擎</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
            <div class="fonts-14 line-height-22 margin-t-15 color-666666">{{info.descr || '暂无描述'}}</div>
            <div class="flex margin-t-20">
                <el-button size="mini" class="margin-r-8" plain round @click="editDialog.show = true" v-if="info.canEdit"><svg-icon name="ib-edit"></svg-icon> 编辑</el-button>
                <div class="share-switch-btn" v-if="info.canEdit">
                    <span class="margin-r-8">共享</span>
                    <el-tooltip :content="share?'关闭共享后，引擎仅对自己可见，其他人不可参与训练和下载测试集。':'开启'" placement="top-start">
                        <next-switch v-model="share" size="small" @change="shareSettingEvent"></next-switch>
                    </el-tooltip>
                </div>
                <el-popover placement="bottom" width="360" popper-class="share-link-popover">
                    <div>
                        <div class="fonts-12">拿到链接的人可获得使用和查看引擎、参与共同训练、下载测试集权限。建议链接和密码点对点发送，不要发到群</div>
                        <div class="margin-t-10">
                            <el-input readonly size="medium" placeholder="请输入内容" v-model="shareLink">
                                <el-button slot="append" @click="copyShareLink">复制链接和密码</el-button>
                            </el-input>
                        </div>
                    </div>
                    <el-button slot="reference" size="mini" plain round class="margin-r-10" v-if="info.canEdit"><svg-icon name="ib-share"></svg-icon> 分享</el-button>
                </el-popover>
                <template v-if="info.modelType === 'cmt'">
                    <el-button size="mini" plain round :loading="downloadTestSetBtnLoading" @click="downloadTestSetBtnEvent"><svg-icon name="ib-download"></svg-icon> 下载测试集</el-button>
                </template>  
            </div>
            <el-divider></el-divider>
            <div class="detail-content">
                <div class="item" style="width: 160px;">
                    <div class="title">语言对</div>
                    <div class="value">{{LANGUAGE_DICT[info.source].name}} <svg-icon name="ib-arrow-right"></svg-icon> {{LANGUAGE_DICT[info.target].name}}</div>
                </div>
                <div class="item" style="flex:1;">
                    <div class="title">引擎标签</div>
                    <div class="value">{{getSelectEngineLabelsName() || '—'}}</div>
                </div>
                <div class="item" style="width: 160px;" v-if="info.modelType === 'cmt'">
                    <div class="title">训练语料</div>
                    <div class="value">{{info.trainSentCount}}条</div>
                </div>
                <div class="item" style="width: 120px;" v-if="info.modelType === 'cmt'">
                    <div class="title">Bleu值评分 <el-tooltip content="Bleu值即机翻质量自动评分，仅供参考" placement="top"><svg-icon name="ib-help-o" className="fonts-14"></svg-icon></el-tooltip></div>
                    <div class="value">{{info.blue}}</div>
                </div>
            </div>
        </el-card>
        <template v-if="info.modelType === 'gpt' && info.canEdit">
            <el-card class="engine-detail-card">
                <el-form label-position="top" :disabled="formDisabled">
                    <el-form-item label="翻译风格">
                        <div class="flex">
                            <div>
                                <el-select v-model="editForm.styleType" :disabled="formDisabled" placeholder="选择风格类型" style="width: 125px;">
                                    <template v-for="(item, index) in styleTypeOptions">
                                        <el-option :label="item.name" :value="item.key" :key="`styleType-${index}`"></el-option>
                                    </template>
                                </el-select>
                            </div>
                            <div class="margin-l-10">
                                <el-select v-model="editForm.translateType" :disabled="formDisabled" placeholder="选择翻译类型" style="width: 125px;">
                                    <template v-for="(item, index) in translateTypeOptions">
                                        <el-option :label="item.name" :value="item.key" :key="`translateType-${index}`"></el-option>
                                    </template>
                                </el-select>
                            </div>
                            <div class="margin-l-10 flex-1">
                                <el-input v-model="editForm.customStyle" :disabled="formDisabled" maxlength="100" show-word-limit placeholder="自定义风格描述，比如“多用长句”"></el-input>
                            </div>
                        </div>
                        
                    </el-form-item>
                    <el-form-item required style="margin-bottom: 0;">
                        <el-image fit="contain" style="display:none;" ref="gptEngineExample"
                            src="https://nextcat.oss-accelerate.aliyuncs.com/fe_projects/example/gpt-engine-sample-1.png?OSSAccessKeyId=LTAI5tLMrodVLgCBBYxPFDRf&Expires=2678700498&Signature=gjCx75VZZgY%2FFT7A4p0YpMxJIqY%3D"
                            :preview-src-list="[
                                'https://nextcat.oss-accelerate.aliyuncs.com/fe_projects/example/gpt-engine-sample-1.png?OSSAccessKeyId=LTAI5tLMrodVLgCBBYxPFDRf&Expires=2678700498&Signature=gjCx75VZZgY%2FFT7A4p0YpMxJIqY%3D',
                                'https://nextcat.oss-accelerate.aliyuncs.com/fe_projects/example/gpt-engine-sample-2.png?OSSAccessKeyId=LTAI5tLMrodVLgCBBYxPFDRf&Expires=2678700523&Signature=NxdJI0eZMBikCfSuGHoiGJRFnhs%3D'
                            ]"></el-image>
                        <template slot="label">精选样例<span class="margin-l-16 fonts-12">添加精心挑选的记忆库样例，代表想要的翻译领域和风格，原文译文总字符数不超过4000个<a class="margin-l-10" href="javacript:;" @click="$refs.gptEngineExample.clickHandler()">查看样例格式</a></span></template>
                        <el-input v-model="editForm.doc" :disabled="formDisabled" class="doc-textarea" type="textarea" rows="10" maxlength="4000" show-word-limit placeholder="粘贴样例到此，段段对照（原文在上、译文在下），或直接复制excel原文和译文列"></el-input>
                    </el-form-item>
                </el-form>
            </el-card>
            <div class="button-content">
                <el-button type="primary" round :loading="submitBtnLoading" :disabled="formDisabled" @click="retrainBtnEvent">重新训练</el-button>
            </div>
        </template>
        <template v-else-if="info.modelType === 'cmt'">
            <el-card class="engine-detail-card">
                <div class="card-title-content">
                    <div class="title">训练记录</div>
                </div>
                <div class="contribute-table">
                    <el-table :data="list">
                    <el-table-column label="贡献者" width="270">
                        <template slot-scope="scope">
                            <div class="name-icon">{{scope.row.createBy.substring(0,1).toUpperCase()}}</div>{{scope.row.createBy}}
                        </template>
                    </el-table-column>
                    <el-table-column label="状态" width="154">
                        <template slot-scope="scope">
                                <template v-if="['train-completed','used'].indexOf(scope.row.status)>-1">
                                    <svg-icon name="ib-dot" style="color:#00B670;"></svg-icon>
                                </template>
                                <template v-else>
                                    <svg-icon name="ib-dot" style="color:#FF9900;"></svg-icon>
                                </template>
                                {{scope.row.description}}
                        </template>
                    </el-table-column>
                    <el-table-column label="新增数据量">
                        <template slot-scope="scope">
                            <el-popover trigger="hover" placement="bottom" width="400">
                                <el-descriptions :column="2">
                                    <el-descriptions-item label="上传句对数">{{scope.row.corpusStats.uploadedSentCount}}条</el-descriptions-item>
                                    <el-descriptions-item label="疑似低质量句对数">{{scope.row.corpusStats.lowQualitySentCount}}条</el-descriptions-item>
                                    <el-descriptions-item label="去重后句对数">{{scope.row.corpusStats.dedupedSentCount}}条</el-descriptions-item>
                                    <el-descriptions-item label="清洗后句对数">{{scope.row.corpusStats.cleanedSentCount}}条</el-descriptions-item>
                                </el-descriptions>
                                <span slot="reference" style="color:#0057FF;">{{scope.row.corpusStats.cleanedSentCount}}条</span>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column label="Bleu值评分" prop="blue"></el-table-column>
                    <el-table-column label="参与时间" width="120">
                        <template slot-scope="scope">{{scope.row.createTime.substring(0,10)}}</template>
                    </el-table-column>
                    </el-table>
                </div>
            </el-card>
            <el-card class="engine-detail-card">
                <div class="card-title-content">
                    <div class="left">
                        <div class="title">参与共同训练</div>
                        <div class="subtitle">只需两步：1.上传语料(xlsx,xls,tmx格式)、2.语料自动清洗和训练</div>
                    </div>
                    <div class="right"></div>
                </div>
                <upload-files
                    ref="fileUpload"
                    title="上传语料"
                    accept=".xlsx,.xls,.tmx"
                    :edit="submitBtnLoading"
                    :size="userInfo.featureConfig.singleFileSize"
                    :limit="50">
                        <template slot="subtitle">
                            <div style="font-size: 12px;line-height: 17px;color: #E98C02;margin-top:5px;">上传语料的领域、语言方向需与引擎保持一致</div>
                        </template>
                </upload-files>
            </el-card>
            <div class="button-content">
                <el-button type="primary" round :loading="submitBtnLoading" @click="submitBtnEvent">提交语料</el-button>
            </div>
        </template>
        <alert-dialog ref="alertDialogDom" 
            :message="alertInfo.message" 
            :type="alertInfo.type" 
            :buttonName="alertInfo.buttonName"
            :buttonType="alertInfo.buttonType"
            @confirmEvent="deleteEngineEvent"></alert-dialog>
        <alert-dialog ref="retrainGptAlertDom" 
            message="重新训练会暂时下线引擎，预计2-3分钟训练完成" 
            type="confirm" 
            @confirmEvent="submitRetrainGpt"></alert-dialog>
        <el-dialog title="修改引擎信息" :visible.sync="editDialog.show" destroy-on-close width="860px" top="20px" @close="editForm.name=''">
            <el-form label-position="top" label-width="80px">
                    <el-form-item label="引擎名称" required>
                        <div class="flex flex-align-center">
                            <div class="flex flex-align-end" :disabled="formDisabled">
                                <el-popover placement="bottom-start" trigger="click">
                                    <div class="engine-icon-list">
                                        <template v-for="(icon, index) in config.ENGINE_ICON_LIST">
                                            <div class="item" :key="index" @click="iconItemClick(icon)">
                                                <svg-icon :name="`ie-${icon}`"></svg-icon>
                                            </div>
                                        </template>
                                    </div>
                                    <div slot="reference" class="flex flex-align-center cursor-pointer">
                                        <div class="create-engine-icon">
                                            <svg-icon :name="`ie-${editForm.icon}`" style="font-size: 28px;"></svg-icon>
                                        </div>
                                        <i class="el-icon-arrow-down fonts-12 margin-l-5"></i>
                                    </div>
                                </el-popover>
                            </div>
                            <el-input v-model="editForm.engineName" placeholder="给自己翻译引擎起个名字，不超过10个字符" :maxlength="10" style="width:300px;margin-left: 10px;"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item required>
                        <template slot="label">引擎标签<span class="form-label-tips">选择1-3个标签</span></template>
                        <el-checkbox-group v-model="editForm.domains" :max="3">
                            <div class="engine-label-grid">
                                <template v-for="(item, index) in engineLabelOptions">
                                    <div class="item" :key="index">
                                        <el-checkbox :key="item.key" :value="item.key" :label="item.key">{{item.name}}</el-checkbox>
                                    </div>
                                </template>
                            </div>
                        </el-checkbox-group>
                        
                    </el-form-item>
                    <el-form-item label="引擎描述" required>
                        <el-input v-model="editForm.descr" type="textarea" rows="2" maxlength="100" show-word-limit :placeholder="`输入简介，100字以内。\n例：国际工程领域，语料主要来自工程技术文件、施工文件、合同等文件。`"></el-input>
                    </el-form-item>
                </el-form>
            <div slot="footer" class="text-center">
                <el-button round plain size="small" @click="editDialog.show = false">取消</el-button>
                <el-button round type="primary" size="small" style="margin-left:30px;" :loading="editDialog.loading" @click="saveEditBtnEvent">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { mapState } from "vuex";
import fileDownload from 'js-file-download';
import config from '@/utils/config';
import { myEngineApi } from '@/utils/api';
import UploadFiles from '@/components/UploadOss';
import AlertDialog from '@/components/AlertDialog';
import PageTitle from '@/components/PageTitle';
import NextSwitch from '@/components/NextSwitch';

export default {
    components: { UploadFiles, AlertDialog, PageTitle, NextSwitch, },
    data(){
        return {
            config,
            share: false,
            info: null,
            list: [],
            fileList: [],
            uploadFileURL:myEngineApi.uploadFile,
            submitBtnLoading: false,
            pollingStatusTimer: null,
            taskId: '',
            sentNumber: 5000,

            downloadTestSetBtnLoading: false,
            alertInfo:{
                message:'确定要删除引擎吗？删除不可恢复',
                type:'confirm', //alert ,confirm
                buttonName:'确认删除',
                buttonType:'danger',
            },
            editDialog:{
                show:false,
                loading:false,
            },
            iconPopoverVisible: false,
            formDisabled: false,
            editForm:{
            },
            shareLink: '',
        }
    },
    computed: {
		...mapState({
			userInfo: state => state.user.userData,
            engineLabelOptions: state => state.common.engineLabels,
            styleTypeOptions: state => state.common.gptEngineStyleTypes,
            translateTypeOptions: state => state.common.gptEngineTranslateTypes,
            LANGUAGE_DICT: state => state.common.LANGUAGE_DICT,
		}),
	},
    created() {
        this.$store.dispatch('common/initDictDataAction',{type: 'mt-domains-tags'});
        this.$store.dispatch('common/initDictDataAction',{type: 'mt-style-type'});
        this.$store.dispatch('common/initDictDataAction',{type: 'mt-translate-type'});
    },
    methods:{
        initPage(){
            let url = `${myEngineApi.queryEngineDetail}?modelId=${this.$route.query.id}`;
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    let data = res.data;
                    this.info = data.engine;
                    let _domains = data.engine.domains ? data.engine.domains: [];
                    this.editForm = {
                        ...data.engine,
                        domains: _domains.map(item=>{
                            return item + '';
                        }),
                        styleType: data.engine.styles ? data.engine.styles.styleType[0] + '' : '',
                        translateType: data.engine.styles ? data.engine.styles.translateType[0] + '' : '',
                        doc: data.models[0].corpusStats.example,
                    };
                    this.list = data.models;
                    this.share = data.engine.sharedType === 'public';
                    if(data.engine.collaToken){
                        this.shareLink = `${location.origin}/engine/s/${data.engine.engineId}/${data.engine.collaToken.token} 密码：${data.engine.collaToken.pwd}`;
                    }
                    if(data.engine.modelType === 'gpt'){
                        if(data.models[0].status !== 'used'){
                            this.formDisabled = true;
                            this.$message.warning({
                                duration: 0,
                                showClose: true,
                                message: '训练中，请稍后刷新页面查看。',
                            });
                        }
                    }
                }
            })
        },
        submitBtnEvent(){
            let fileList = this.$refs.fileUpload.getOssFiles();
            this.fileList = fileList;
            if (fileList.length === 0) {
                this.$message.error('请上传语料');
                return;
            }
            let postData = {
                docs: fileList,
                sourceLang: this.info.source,
                targetLang: this.info.target,
            };
            this.submitBtnLoading = true;
            let url = myEngineApi.addCheckCorpusTask;
            // 1. 开始语料验证
            this.$ajax.post(url, postData).then(res=>{
                if(res.status === 200){
                    this.taskId = res.data;
                    if(this.pollingStatusTimer === null){
                        this.getTaskStatus();
                        this.pollingStatusTimer = setInterval(()=>{
                            this.getTaskStatus();
                        },2000);
                    }
                }
            }).catch(e => {
                this.submitBtnLoading = false;
            });
        },
        retrainBtnEvent(){
            this.$refs.retrainGptAlertDom.setShow(true);
        },
        submitRetrainGpt(){
            let url = myEngineApi.retrainGptEngine;
            let postData = {
                engineId: this.info.engineId,
                styles: {
                    styleType: [this.editForm.styleType],
                    translateType: [this.editForm.translateType],
                },
                customStyle: this.editForm.customStyle,
                corpus: this.editForm.doc,
            };
            this.submitBtnLoading = true;
            this.formDisabled = true;
            this.$ajax.post(url, postData).then(res => {
                if(res.status === 200){
                    this.$message.warning({
                        duration: 0,
                        showClose: true,
                        message: '训练中，请稍后刷新页面查看。',
                    });
                }
            }).catch(e => {
                this.submitBtnLoading = false;
                this.formDisabled = false;
            });
        },

        getTaskStatus(){
            let url = `${myEngineApi.queryData}?taskId=${this.taskId}`;
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    let data = res.data;
                    if(data.stage === 'preprocess'){
                        if(data.status === 'error'){
                            this.$message.error(`句段数不足${this.sentNumber}条，请继续上传语料`);
                            this.submitBtnLoading = false;
                            clearInterval(this.pollingStatusTimer);
                            this.pollingStatusTimer = null;
                        }else if(data.status === 'completed'){
                            clearInterval(this.pollingStatusTimer);
                            this.pollingStatusTimer = null;
                            this.submintCorpusBuild();
                        }
                    }
                }
            })
        },
        submintCorpusBuild(){
            let url = myEngineApi.shareBuildAppendCorpus;
            let postData = {
                source: this.info.source,
                target: this.info.target,
                engineId: this.info.engineId,
                engineName: this.info.engineName,
                taskId: this.taskId,
                docs: this.fileList,
            }
            this.$ajax.post(url,postData).then(res=>{
                if(res.status === 200){
                    location.reload(true);
                }
            }).finally(()=>{
                this.submitBtnLoading = false;
            });
        },
        commandHandle(command){
            switch (command){
                case 'rename':
                    this.editDialog.show = true;
                    this.editForm.name = this.info.engineName;
                    break;
                case 'delete':
                    this.$refs.alertDialogDom.setShow(true);
                    break;
            }
        },
        deleteEngineEvent(){
            let self = this;
            let url = this.$ajax.format(myEngineApi.deleteEngine,{id: this.info.engineId});
            this.$ajax.delete(url).then(res=>{
                if(res.status === 200){
                    this.$message.success('引擎已删除');
                    setTimeout(()=>{
                        self.$router.replace('/engine/config');
                    },1000);
                }
            })
        },
        saveEditBtnEvent(){
            if(!this.editForm.engineName){
                this.$message.error('请输入引擎名称');
                return;
            }
            if(this.editForm.domains.length === 0){
                this.$message.error('请选择引擎标签');
                return;
            }
            if(!this.editForm.descr){
                this.$message.error('请输入引擎描述');
                return;
            }
            this.editDialog.loading = true;
            let url = myEngineApi.updateEngineName;
            this.$ajax.put(url,{
                engineId: this.info.engineId,
                engineName: this.editForm.engineName,
                icon: this.editForm.icon,
                domains: this.editForm.domains || [],
                descr: this.editForm.descr,
            }).then(res=>{
                if(res.status === 200){
                    this.editDialog.show = false;
                    this.$message.success('修改成功');
                    this.initPage();
                }
            }).finally(()=>{
                this.editDialog.loading = false;
            })
        },
        /**下载测试集 */
        downloadTestSetBtnEvent(){
            let downMsg = this.$message({
                message: '下载中...',
                type: 'info',
                iconClass: 'el-icon-loading',
                duration: 0,
            });
            this.downloadTestSetBtnLoading = true;
            let url = this.$ajax.format(myEngineApi.downloadTestSet,{id: this.$route.query.id});
            url = url + `?target=${this.info.target}`;
            this.$ajax.axios.get(url,{responseType:'blob'}).then(res=>{
                let timestamp = new Date().getTime();  
                fileDownload(res, `${this.info.engineName}-测试集-${timestamp}.xlsx`);
            }).finally(()=>{
                this.downloadTestSetBtnLoading = false;
                downMsg.close();
            })
        },
        shareSettingEvent(val){
            let url = this.$ajax.format(myEngineApi.shareSetting, {id: this.info.engineId});
            this.$ajax.post(url,{type: val?'public':'private'}).then(res => {
                if (res.status === 200){
                    if(val){
                        this.$message.success('引擎共享已开启');
                    }else{
                        this.$message.success('引擎共享已关闭');
                    }
                    this.info.shared = val;
                }else{
                    this.share = !val;
                }
            }).catch(e => {
                this.share = !val;
            }).finally(() => {
            });
            // console.log(val, url);
        },
        copyShareLink(){
            let self = this;
            let text = `${this.userInfo.nickname || this.userInfo.username || this.userInfo.phone}邀请你使用他/她的专属个性引擎${this.info.engineName}，接受邀请后，你的翻译引擎阵列中就可以看到了~\n`+
                this.shareLink;
            this.$copyText(text).then(function (e) {
                self.$message.success('已复制到剪切板');
            }, function (e) {
                self.$message.error('复制到剪切板失败，请重试');
            })
        },
        getSelectEngineLabelsName(){
            let rst = [];
            if (this.info.domains){
                this.engineLabelOptions.map(item => {
                    if(this.info.domains.indexOf(item.key*1) > -1){
                        rst.push(item.name);
                    }
                });
            }
            return rst.join("、");
        },
        iconItemClick(icon){
            this.editForm.icon = icon;
            this.iconPopoverVisible = false;
        },
    },
    mounted(){
        this.initPage();
        // console.log('checkPermission::', this.$checkPermission('engine1'))
    },
}
</script>
<style lang="scss" scoped>
.engine-detail-page{
    padding: 20px 0 80px 0;
    width: 860px;
    margin: auto;
    ::v-deep .el-table th{
        background-color:#F8F8F8;
        color: #000000;
        font-size: 15px;
    }
    ::v-deep .page-title-component{
        border: none;
    }
    .quick-engine-lable{
        height: 24px;
        font-size: 12px;
        color: #E98C02;
        background-color: #FFEBC3;
        padding: 0 11px;
        border-radius: 50px;
        display: flex;
        align-items: center;
    }
    .deep-engine-lable{
        height: 24px;
        font-size: 12px;
        color: #2979FF;
        background-color: #EBF0FF;
        padding: 0 11px;
        border-radius: 50px;
        display: flex;
        align-items: center;
    }
}
.engine-detail-card{
    margin-top: 20px;
    border-radius: 10px;
    border: none;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
    // background-color:#FFFFFF;
    ::v-deep .el-card__body{
        padding: 30px;
    }
    ::v-deep .el-divider{
        background-color: #f2f2f2;
        margin: 20px 0 30px 0;
    }
    ::v-deep .el-input__count{
        line-height: 1;
    }
}
.share-switch-btn{
    height: 28px;
    padding: 0 15px;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #606266;
    background: #FFFFFF;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 32px;
    margin-right: 10px;
    &:hover{
        border-color: #000000;
        color: #000000;
    }
}
.card-title-content{
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    // border-bottom: 1px solid #0000001a;
    box-sizing: border-box;
    .title{
        font-size: 18px;
        font-weight: 500;
        color: #000000;
        line-height: 25px;
        margin-bottom: 5px;
    }
    .subtitle{
        font-size: 15px;
        color: #999999;
        line-height: 24px;
    }
}
.title-header-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left{
        display: flex;
        svg{
            font-size: 28px;
        }
        align-items: center;
    }
    .right{
        display: flex;
        align-items: center;
        
    }
    .engine-name{
        font-size: 24px;
        font-weight: 500;
    }
}
.detail-content{
    margin-top: 30px;
    display: flex;
    .item{
        width: 232px;
        > .title{
            margin-bottom: 16px;
            font-size: 12px;
            color: #808080;
        }
        > .value{
            font-weight: 500;
            font-size: 20px;
        }
    }
}
.contribute-table{
    // margin-top: 40px;
    .name-icon{
        width: 24px;
        height: 24px;
        display: inline-block;
        margin-right: 6px;
        border-radius: 50%;
        line-height: 24px;
        text-align: center;
        background-color: #EBEBEB;
    }
}
.button-content{
    text-align: center;
    margin-top: 20px
}
.engine-label-grid{
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill,minmax(100px,1fr));
    line-height: 1;
}
.engine-icon-list{
    display: flex;
    .item {
        font-size: 24px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        &:first-child{
            margin-left: 0;
        }
        &:hover{
            background-color: #f2f2f2;
        }
    }
}
.create-engine-icon{
    width: 48px;
    height: 48px;
    border-radius: 50%;
    box-sizing: border-box;
    border: 1px solid #cccccc;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &[disabled]{
        pointer-events: none;
        opacity: 0.4;
    }
}
.form-label-tips{
    color: #E98C02;
    font-size: 12px;
    margin-left: 16px;
}
.doc-textarea{
    ::v-deep .el-textarea__inner{
        border-color: #7DD6B3;
    }
}
</style>
<style lang="scss">
.share-link-popover{
    border-radius: 10px;
    padding: 20px;
    .el-input-group__append{
        background-color:#000000;
        color: #fff;
        border: 1px solid #000000;
    }
}
</style>
